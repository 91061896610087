import axios from "axios";

$(document).on("turbolinks:load", function () {
    //let token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    //axios.defaults.headers.common['X-CSRF-Token'] = token
    //axios.defaults.headers.common['Accept'] = 'application/json'

    window.companyChange = function (event, id) {
        let token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
        //axios.defaults.headers.common['X-CSRF-Token'] = token
        //axios.defaults.headers.common['Accept'] = 'application/json'
        let url = '/companies/change.json'
        let formContents;


        if (event) {
            event.preventDefault();
        }

        formContents = {
            ajax_params: {
                id: id,
            }
        };

        $.ajax({
            url: '/companychange',
            data: {
                authenticity_token: $('[name="csrf-token"]')[0].content,
                id: id
            },
            method: 'POST',
            success: function(res) {
                location.reload();
            }
        })

        //axios.patch(url, formContents).then(function (response) {

        //    if (response.status === 200 && response.data.success == true) {
        //        iziToast.success({message: 'Troca efetuada com sucesso!', position: "topRight"});
                //location.reload();
        //    } else {
         //       iziToast.error({message: 'Algo deu errado!', position: "topRight"});
        //    }

        //}).catch(function (error) {
        //    iziToast.error({message: 'Algo deu errado!', position: "topRight"});
        //    console.log(error.statusText);
        //});
    }

});