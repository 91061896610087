// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// Core libraries
require("turbolinks").start()
require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")

// jQuery (as a read only property so browser extensions can't clobber it)
const jquery = require("jquery")
const descriptor = { value: jquery, writable: false, configurable: false }
Object.defineProperties(window, { $: descriptor, jQuery: descriptor })

// App libraries
require("bootstrap")
require("jquery.nicescroll")

require("select2")
require("cleave.js")
require("waypoints/lib/noframework.waypoints")
require("waypoints/lib/shortcuts/infinite")

// Graph
require("chartkick")
require("chart.js")

import "chartkick/chart.js"

window.dispatchEvent(new Event("chartkick:load"));

// will be part of Chartkick.js 4.0
Chartkick.destroyAll = function() {
  for (var id in Chartkick.charts) {
    Chartkick.charts[id].destroy();
    delete Chartkick.charts[id];
  }
}

// may be part of Chartkick.js 4.0 or may leave it to apps to add
document.addEventListener("turbolinks:before-render", function() {
  Chartkick.destroyAll();
});


window.iziToast = require("izitoast")

const flatpickr = require("flatpickr")
const Portugues = require("flatpickr/dist/l10n/pt.js").default.pt;
//const Portugues = require("vendors/flatpickr-pt.min.js").default.pt;
flatpickr.localize(Portugues);

window.Dropzone = require("dropzone")

// Stisla
require("vendors/stisla/stisla")
require("vendors/stisla/scripts")
require("vendors/custom_modal")

//Bootstrap Table
require("bootstrap-table/dist/bootstrap-table.js")
require("vendors/bootstrap-table/bootstrap-table-pt-BR")
require("vendors/bootstrap-table/filter-control/utils")
require("vendors/bootstrap-table/filter-control/bootstrap-table-filter-control")

require("controllers/company_change.js")

require("vendors/jquery.doubleScroll.js")
require("@nathanvda/cocoon")

// Application
require("app").start()

import "controllers"
