import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    var options = {}
    if(this.data.get("max"))
      options["maximumSelectionLength"] = this.data.get("max")
    $(this.element).select2(options)
  }

  disconnect() {
    $(this.element).select2("destroy")
  }

}
